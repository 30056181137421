<template lang="">
    <DataTable dataKey="id" :value="records" :row-hover="true" class="p-datatable-sm" responsiveLayout="stack" breakpoint="640px">
        <template #empty> Nenhum registro encontrado. </template>

        <template #loading> Carregando registros. Aguarde ... </template>
        <Column field="id" header="Código"></Column>
        <Column field="status" header="Status"></Column>
        <Column field="operacao" header="Operação"></Column>
        <Column field="erro" header="Erro"></Column>
        <Column field="idOrigem" header="Cód. Origem"></Column>
        <Column field="dados" header="Json"></Column>
    </DataTable>
</template>
<script>
export default {
    props: ['records']
};
</script>
